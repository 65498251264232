const getColumn = (newData) => {
	let column = [];

	for (let key in newData[0]) {
		column.push({ header: key.toUpperCase().replace(/_/g, ' '), field: key });
	}
	return column;
};

export default getColumn;
