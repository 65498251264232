import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Spinner, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import Navigation from '../components/Navigation';
import axios from 'axios';
import ReactExport from 'react-export-excel';
import Swal from 'sweetalert2';
import host from '../hooks/host';
// import convertData from '../hooks/convertData';
import changeFormat from '../hooks/changeFormat';
import getColumn from '../hooks/getColumn';

export default () => {
	const history = useHistory();
	// const onActive = '/claimkematian';
	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	// const [data, setData] = useState([]);
	const [downloadData, setDownloadData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [column, setColumn] = useState();
	const [reData, setReData] = useState([]);

	useEffect(() => {
		// fetchApi();
		getData();
	}, []);

	useEffect(() => {
		resetColumn();
		// eslint-disable-next-line
	}, [reData]);

	const getData = async () => {
		try {
			const getClaimKematian = await axios({
				method: 'GET',
				url: `${host}/data/claim-kematian`,
				headers: {
					token: localStorage.token,
				},
			});
			const getClaimKesehatan = await axios({
				method: 'GET',
				url: `${host}/data/claim-kesehatan`,
				headers: {
					token: localStorage.token,
				},
			});
			const getClaimManfaat = await axios({
				method: 'GET',
				url: `${host}/data/claim-nilai-hidup`,
				headers: {
					token: localStorage.token,
				},
			});
			const getClaimKacamata = await axios({
				method: 'GET',
				url: `${host}/data/claim-pendidikan`,
				headers: {
					token: localStorage.token,
				},
			});

			setReData([
				...changeFormat(getClaimKematian.data, 'kematian'),
				...changeFormat(getClaimKesehatan.data, 'kesehatan'),
				...changeFormat(getClaimManfaat.data, 'manfaat'),
				...changeFormat(getClaimKacamata.data, 'kacamata'),
			]);
			setDownloadData([
				...changeFormat(getClaimKematian.data, 'kematian'),
				...changeFormat(getClaimKesehatan.data, 'kesehatan'),
				...changeFormat(getClaimManfaat.data, 'manfaat'),
				...changeFormat(getClaimKacamata.data, 'kacamata'),
			]);
			// setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};
	const resetColumn = () => {
		setColumn(getColumn(reData));
		setReData(reData.sort((a, b) => a.id - b.id));
		setLoading(false);
	};

	console.log(column);

	console.log('combine', reData);

	// const fetchApi = async () => {
	// 	const { data } = await axios({
	// 		method: 'GET',
	// 		url: `${host}/data/claim-kematian`,
	// 		headers: {
	// 			token: localStorage.token,
	// 		},
	// 	});
	// 	console.log(data);
	// 	setData(data);
	// 	setDownloadData(convertData(data, 'kematian'));
	// 	setLoading(false);
	// };
	const pageTransition = {
		init: {
			opacity: 0,
		},
		in: {
			opacity: 1,
		},
		out: {
			opacity: 0,
		},
	};

	function handdleDownload() {
		Swal.fire({
			icon: 'success',
			title: 'Claim data downloaded',
			showConfirmButton: false,
			timer: 1500,
		});
	}
	return (
		<motion.div initial='init' animate='in' exit='out' variants={pageTransition}>
			<Navigation />
			{/* {JSON.stringify(data)} */}
			<h1 style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>Data Pengajuan Claim Kematian</h1>
			<div style={{ marginLeft: 10, marginRight: 10 }}>
				{!loading ? (
					<div style={{ marginBottom: 10 }}>
						<ExcelFile
							element={
								<Button variant='success' onClick={handdleDownload}>
									Download
								</Button>
							}
							filename='Pengajuan Claim Kematian'>
							<ExcelSheet data={downloadData} name='Data Claim'>
								{column &&
									column.map((item, index) => {
										return <ExcelColumn key={index} label={item.header} value={item.field} />;
									})}

								{/* <ExcelColumn label='Nama' value='nama' />
								<ExcelColumn label='No Induk' value='no_induk' />
								<ExcelColumn label='Surat Permohonan Ahli Waris' value='permohonan_ahli_waris' />
								<ExcelColumn label='Surat Keterangan Meninggal dari Lurah' value='keterangan_menginggal_dunia_lurah' />
								<ExcelColumn label='Surat Keterangan Meninggal dari Rumah Sakit' value='keterangan_meninggal_dunia_rumah_sakit' />
								<ExcelColumn label='Surat Keterangan dari Kepolisian' value='keterangan_kepolisian' />
								<ExcelColumn label='Fotokopi KTP' value='fotokopi_kp' />
								<ExcelColumn label='Fotokopi KK' value='fotokopi_kk' />
								<ExcelColumn label='Fotocopi SK Pengangkatan' value='fotokopi_sk_pengangkatan' />
								<ExcelColumn label='Fotokopi SK Pensiun' value='fotokopi_sk_pensiun' /> */}
							</ExcelSheet>
						</ExcelFile>
					</div>
				) : null}
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th className='small'>No</th>
							<th className='small'>Nama</th>
							<th className='small'>No Induk</th>
							<th className='small'>Satuan Kerja</th>
							<th className='small'>Golongan</th>
							<th className='small'>Waktu Pengajuan</th>
							<th className='small'>Jenis Claim</th>
							<th className='small'>Action</th>
						</tr>
					</thead>
					{loading ? (
						<tbody>
							<tr>
								<td colSpan='6' className='small' style={{ textAlign: 'center' }}>
									<Spinner animation='border' variant='success' />
								</td>
							</tr>
						</tbody>
					) : reData.length === 0 ? (
						<tbody>
							<tr>
								<td colSpan='6' className='small' style={{ textAlign: 'center' }}>
									Tidak Ada Pengajuan
								</td>
							</tr>
						</tbody>
					) : (
						<tbody>
							{column &&
								reData.map((file, idx) => {
									return (
										<tr key={idx} style={{ cursor: 'pointer' }}>
											<td className='small'>{idx + 1}</td>
											<td className='small'>{file.nama}</td>
											<td className='small'>{file.no_induk}</td>
											<td className='small'>{file.satuan_kerja}</td>
											<td className='small'>{file.golongan_pangkat}</td>
											<td className='small'>{file.waktu_pengajuan}</td>
											<td className='small'>{file.claim}</td>
											<td>
												<Button variant='primary' size='sm' onClick={() => history.push(`detail/${file.claim}/${file.id}`)}>
													Check
												</Button>
											</td>
										</tr>
									);
								})}
						</tbody>
					)}
				</Table>
			</div>
		</motion.div>
	);
};
